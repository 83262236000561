.title {
    text-align: center;
    font-family: 'PoppinsSemi', sans-serif;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    color: #00BB98;
}

.subtitle {
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: #000B33;
    line-height: 57.6px;
    width: 30%;
    margin: 20px auto 20px;
}

.container {
    width: 75%;
    margin: 0 auto;
}

.coments_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.img_block {
    flex: 0 1 40%;
}

.img_block img {
    width: 100%;
}

.swiper_comments_wrapper {
    flex: 0 1 50%;
}

.mySwiper {
    width: 700px;
    height: 400px;
}

.mySwiper .photo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 25px;
}

.mySwiper .comment_block {
    width: 100%;
    height: 400px;
    background-color: #fff;
    box-shadow: 10px 10px 35px 8px rgb(149, 149, 149);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
}

.mySwiper .comment {
    font-family: 'PoppinsMedium', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    width: 80%;
    margin-bottom: 40px;
    color: #000B33;
}

.mySwiper .info_parent_block {
    display: flex;
    align-items: center;
}

.mySwiper .parent_name_block h4 {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #000B33;
}

.mySwiper .parent_name_block h6 {
    font-family: 'PoppinsMedium', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #000B33;
}

@media(min-width:1921px) {
    .container {
        max-width: 1440px;
    }
}

@media(max-width:1400px) {
    .mySwiper {
        width: 600px;
    }

    .mySwiper .comment {
        font-size: 16px;
    }
}

@media(max-width:1280px) {
    .subtitle {
        font-size: 36px;
        width: 40%;
    }

    .mySwiper {
        width: 500px;
        height: 350px;

    }

    .mySwiper .comment {
        font-size: 14px;
    }

    .mySwiper .photo {
        width: 50px;
        height: 50px;
    }

    .mySwiper .parent_name_block h4 {
        font-size: 18px;
    }

}

@media(max-width:992px) {
    .mySwiper {
        width: 400px;
    }

    .subtitle {
        width: 50%;
    }
}

@media(max-width:768px) {
    .title {
        color: #4BFFDD;
        padding-top: 20px;
    }

    .subtitle {
        font-size: 26px;
        width: 80%;
    }

    .comments_section {
        background-color: #5FAFCE;
        height: 130vh;
    }

    .coments_wrapper {
        flex-direction: column;
    }

    .mySwiper {
        width: 480px;
    }

    .mySwiper .comment {
        color: #000;
        font-weight: 600;
    }

    .img_block {
        flex: 0 1 30%;
        width: 50%;
    }

}

@media(max-width:500px) {
    .mySwiper {
        width: 415px;
    }

    .comments_section {
        height: 110vh;
    }
}

@media(max-width:430px) {
    .mySwiper {
        width: 350px;
    }

    .mySwiper .comment {
        line-height: 18px;
    }
}