.burgerMenu {
    width: 50px;
    height: 50px;
}

.menu_btn_active,
.menu_btn {
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    background: #5FAFCE;
    border-radius: 10px;
    border: 1px solid #fff;
}


.menu_btn_active span,
.menu_btn span {
    width: 30px;
    height: 3px;
    position: absolute;
    background-color: #fff;
    transition: all 0.5s;
    z-index: 100;
    left: 20%;
    top: 25%;
}

.menu_btn span {}


.menu_btn span:nth-of-type(2) {
    top: calc(55% - 5px);
}

.menu_btn span:nth-of-type(3) {
    top: calc(55% + 5px);
}

.menu_btn_active span:nth-of-type(1) {
    display: none;
}

.menu_btn_active span:nth-of-type(2) {
    top: 45%;
    transform: rotate(45deg);
}

.menu_btn_active span:nth-of-type(3) {
    top: 45%;
    transform: rotate(-45deg);
}


.menu_active,
.menu {
    position: fixed;
    width: 90%;
    top: 0px;
    right: 0px;
    height: 100vh;
    background: #5FAFCE;
    transition: 0.5s;
    z-index: 80;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-right: 5%;
}

.menu_active {
    transform: translateX(0);

}

.menu {
    transform: translateX(100%);
}

.menu nav ul li {
    list-style-type: none;
}

.links {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
}



.navigation {
    margin-top: 90px;

}

.navigation ul li {
    line-height: 6;
    margin-left: 30px;
}

.navigation ul li::after {
    content: '';
    display: block;
    width: 90%;
    height: 1px;
    background: rgb(255, 255, 255);
}

.connect_btn {
    width: 258px;
    height: 44px;
    background: #2782A3;
    border-radius: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #fff;
    margin: 30px 0 20% 30px;
}

.box_contact_info {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.box_contact_info a {
    flex: 0 1 20%;
    cursor: pointer;
}

.box_contact_info a img {
    width: 100%;
}

@media (min-width:767px) {
    .burgerMenu {
        display: none;
    }
}