.news_card {
    flex: 0 0 23%;
    height: 500px;
    display: inline-block;
    border-radius: 20px;
    margin: 0 12px;
    -webkit-box-shadow: 0px 5px 10px 5px rgba(70, 69, 69, 0.5);
    box-shadow: 0px 5px 10px 5px rgba(70, 69, 69, 0.5);
    transition: 0.4s;
}

.news_photo {
    width: 100%;
    height: 225px;
    object-fit: cover;
    object-position: center;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-bottom: 20px;
}

.news_container {
    width: 95%;
    margin: 0 auto;
}

.created {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6F6F6F;
}

.news_container h4 {
    font-size: 18px;
    color: #000B33;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    height: 60px;
    line-height: 26px;
}

.description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #6F6F6F;
    line-height: 16.94px;
    padding: 5px 0;
    height: 80px;
}

.more_wrapper {
    display: flex;
    justify-content: flex-end;
}

.more {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    margin: 10px 0;
    color: #000B33;
    background: transparent;
}

.author_block {
    display: flex;
    align-items: center;
}

.author_photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-right: 10px;
}

.author_block span {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #000;
}

@media(max-width:1280px) {
    .news_card {
        flex: 0 0 30%;
    }
}

@media(max-width:992px) {
    .news_card {
        flex: 0 0 40%;
    }
}

@media(max-width:767px) {
    .news_card {
        flex: 0 0 65%;
    }
}

@media(max-width:430px) {
    .news_card {
        flex: 0 0 70%;
    }

    .news_container h4 {
        font-size: 16px;
        line-height: 18px;
    }
}