.wrapper_swiper {
    position: relative;
}

.swiper {
    height: 300px;
}

.swiper-slide {
    text-align: center;
    margin-right: 0;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.ellips_top {
    width: 100%;
    height: 50px;
    border-bottom-left-radius: 150%;
    border-bottom-right-radius: 150%;
    position: absolute;
    top: 0%;
    left: 0;
    z-index: 1000;
    background-color: #fff;
}

.ellips_bottom {
    width: 100%;
    height: 50px;
    border-top-left-radius: 150%;
    border-top-right-radius: 150%;
    position: absolute;
    bottom: -5%;
    left: 0;
    z-index: 1000;
    background-color: #ffffff;
}

/* @media(max-width:1600px) {
    .swiper-slide img {
        width: 500px;
    }
} */


@media(max-width:1280px) {
    .swiper-slide img {
        width: 100%;

    }
}

@media(max-width:1024px) {
    .swiper-slide img {
        width: 110%;

    }
}

@media(max-width:992px) {
    .wrapper_swiper {
        /* height: 60vh; */
    }

    .ellips_bottom {
        display: none;
    }

    .ellips_top {
        display: none;
    }

    .wrapper_swiper {
        margin-bottom: 60px;
    }

    .wrapper_swiper .swiper {
        width: 500px;
        height: 500px;
    }

    .wrapper_swiper .swiper-slide {
        background-position: center;
        background-size: cover;
    }

    .wrapper_swiper .swiper-slide img {
        display: block;
        width: 100%;
    }

    .wrapper_swiper .swiper-button-prev,
    .wrapper_swiper .swiper-button-next {
        width: 50px;
        height: 50px;
        background: #2782A3;
        border-radius: 50%;
        transition: .5s;
        color: #fff;
    }

    .wrapper_swiper .swiper-button-prev::after,
    .wrapper_swiper .swiper-button-next::after {
        font-size: 30px;
        font-weight: 600;
    }
}

@media(max-width:767px) {
    .wrapper_swiper .swiper {
        width: 400px;
        height: 400px;
    }
}

@media(max-width:430px) {
    .wrapper_swiper .swiper {
        width: 350px;
        height: 350px;
    }

    .wrapper_swiper .swiper-button-prev,
    .wrapper_swiper .swiper-button-next {
        width: 35px;
        height: 35px;
    }

    .wrapper_swiper .swiper-button-prev::after,
    .wrapper_swiper .swiper-button-next::after {
        font-size: 28px;
    }
}