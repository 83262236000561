.gallery_block {
    margin-bottom: 50px;
}

.title {
    font-family: 'PoppinsSemi', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: #00BB98;
    margin: 20px 0;
}

.our_photos {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 79px;
}

@media(max-width:1200px) {
    .our_photos {
        font-size: 36px;
    }
}

@media(max-width:992px) {
    .our_photos {
        margin-bottom: 30px;
    }
}