@font-face {
    font-family: 'PoppinsRegular';
    src: url('./fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'PoppinsMedium';
    src: url('./fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'PoppinsSemi';
    src: url('./fonts/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-VariableFont_slnt\,wght.ttf');
}

.swiper_comments {
    height: 100%;
}

.swiper_comments .swiper-button-prev,
.swiper_comments .swiper-button-next {
    width: 50px;
    height: 50px;
    background: #2782A3;
    border-radius: 50%;
    transition: .5s;
    color: #fff;
}

.swiper_comments .swiper-button-prev::after,
.swiper_comments .swiper-button-next::after {
    font-size: 30px;
    font-weight: 600;
}

@media(max-width:1400px) {

    .swiper_comments .swiper-button-prev,
    .swiper_comments .swiper-button-next {
        width: 40px;
        height: 40px;
    }

    .swiper_comments .swiper-button-prev::after,
    .swiper_comments .swiper-button-next::after {
        font-size: 25px;
        font-weight: 600;
    }
}

@media(max-width:1280px) {

    .swiper_comments .swiper-button-prev,
    .swiper_comments .swiper-button-next {
        width: 30px;
        height: 30px;
    }

    .swiper_comments .swiper-button-prev::after,
    .swiper_comments .swiper-button-next::after {
        font-size: 20px;
        font-weight: 400;
    }

}