.document_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(53, 53, 209, 0.421);
    backdrop-filter: blur(20px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_container {
    position: relative;
    width: 30%;
    height: 40%;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.document_items {
    cursor: pointer;
    font-family: 'PoppinsRegular', sans-serif;
    color: #14013F;
    font-size: 18px;
    font-weight: 400;
    transition: 0.3s;
}

.document_items:hover {
    text-decoration: underline;
}

.close {
    position: absolute;
    top: 3%;
    right: 2%;
    width: 33px;
    height: 33px;
    cursor: pointer;
    transition: 0.4s;
}

.close:hover {
    filter: drop-shadow(0px 0px 10px red);
}

@media(max-width:1024px) {
    .document_items {
        font-size: 16px;
    }
}

@media(max-width:992px) {
    .modal_container {
        width: 60%;
        height: 60%;
    }
}

@media(max-width:767px) {
    .modal_container {
        width: 80%;
        height: 60%;
    }
}