.video_section {
    margin: 150px 0;
    height: 85vh;
}

.player {
    width: 100%;
    height: 85%;
}

.video_section h2 {
    text-align: center;
    font-family: 'PoppinsSemi', sans-serif;
    font-size: 40px;
    font-weight: 600;
}


@media(max-width:767px) {
    .video_section {
        margin: 50px 0;
        height: 50vh;
    }
}

@media(max-width:430px) {
    .player {
        height: 250px;
    }
}