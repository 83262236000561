.teacher_card {
    flex: 0 1 23%;
    border-radius: 20px;
    -webkit-box-shadow: 5px 5px 15px 5px #6b6b6b6d;
    box-shadow: 5px 5px 15px 5px #6b6b6b6d;
    overflow: hidden;
    transition: 0.4s;
    padding: 0 5px;
    margin-bottom: 40px;
    background: url('../../../assets/hat.png') left 2% top 8% no-repeat;
}

.teacher_card:hover {
    transform: scale(1.05);
}

.position {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 10px 20px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #000000;
    margin-bottom: 20px;
    border-left: 2px dashed #000;
    border-bottom: 2px dashed #000;
    border-right: 2px dashed #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.teacher_photo_block {
    width: 90%;
    margin: 0 auto;
}

.teacher_photo_block img {
    width: 100%;
    height: 325px;
    object-fit: cover;
    object-position: center 0%;
    border-radius: 20px;
}

.teacher_name {
    font-family: 'Intee', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: #000;
    text-align: center;
    height: 70px;
    padding: 0px 30px;
    margin: 30px auto 0;
}

@media(max-width:1440px) {
    .position {
        padding: 5px;
    }

    .teacher_card {
        background-position: left 2% top 11%;
    }

    .teacher_name {
        font-size: 18px;
        padding: 0 20px;
    }
}

@media(max-width:1280px) {
    .position {
        font-size: 14px;
    }

    .teacher_card {
        background-position: left 2% top 13%;
    }

    .teacher_photo_block {
        width: 95%;
    }

    .teacher_name {
        font-size: 16px;
        padding: 0;
    }
}

@media(max-width:992px) {
    .position {
        padding: 0;
        font-size: 12px;
    }

    .teacher_card {
        background-position: left 2% top 20%;
    }

    .teacher_name {
        font-size: 14px;
        line-height: 18px;
    }
}

@media(max-width:992px) {
    .position {
        font-size: 16px;
    }

    .teacher_card {
        flex: 0 0 33%;
        background-position: left 2% top 10%;
    }

    .teacher_name {
        font-size: 16px;
    }

    .teacher_card:hover {
        transform: none;
    }
}

@media(max-width:767px) {
    .teacher_card {
        flex: 0 0 40%;
    }
}

@media(max-width:560px) {
    .teacher_card {
        flex: 0 0 60%;
    }
}

@media(max-width:430px) {
    .teacher_card {
        flex: 0 0 70%;
    }

}