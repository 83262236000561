.modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(138, 138, 138, 0.4);
    backdrop-filter: blur(15px);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_container {
    position: relative;
    width: 85%;
    height: 90%;
    background: url('../../../assets/Ellipse\ 61.png') left top -30px no-repeat, url('../../../assets/Ellipse-yellow.png') right bottom no-repeat;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.387);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.close {
    position: absolute;
    top: 3%;
    right: 2%;
    width: 33px;
    height: 33px;
    cursor: pointer;
    transition: 0.4s;
}

.close:hover {
    filter: drop-shadow(0px 0px 10px red);
}

.title {
    margin: 24px 0;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 26px;
    text-shadow: 3px 3px 5px rgba(25, 25, 203, 0.645);
}


.news_image_block img {
    width: 400px;
    height: 300px;
    border-radius: 20px;
    object-fit: cover;
    object-position: center;
}

.news_description {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    width: 60%;
    margin-top: 26px;
    margin-bottom: 20px;
    white-space: break-spaces;
    color: #6F6F6F;
    max-height: 46%;
    overflow-y: auto;
}

.news_description::-webkit-scrollbar {
    width: 12px;

}

.news_description::-webkit-scrollbar-track {
    background-color: #808080;
    border-radius: 10px;
    cursor: pointer;
    -webkit-cursor: pointer;
}

.news_description::-webkit-scrollbar-thumb {
    background-color: #235cd7;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    cursor: pointer;
    width: 5px;
}

.news_description::-webkit-scrollbar-thumb:hover {
    background-color: #cfdd10;
}


@media(max-width:1440px) {
    .news_image_block img {
        width: 350px;
        height: 250px;
    }
}

@media(max-width:1024px) {
    .news_image_block img {
        width: 300px;
        height: 200px;
    }

    .news_description {
        width: 80%;
    }
}

@media(max-width:1024px) {
    .news_image_block img {
        width: 250px;
        height: 150px;
    }

    .news_description {
        width: 90%;
    }
}

@media(max-width:430px) {
    .modal_container {
        width: 95%;
        height: 95%;
    }

    .title {
        width: 80%;
        font-size: 16px;
    }
}